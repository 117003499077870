import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonC, CommonI } from '../models/common';
import { GlobalUrl } from './webapiurl';

@Injectable({
  providedIn: 'root'
})
export class PaymentNotificationService {

  public commonModel: CommonI;
  public url: string;

  constructor(
    private http: HttpClient
  ) {

    this.commonModel = new CommonC();

    this.url = GlobalUrl.url_webapi;
  }

  getRecordByCheck(fraccionamiento: any): Observable<any> {
    const params = JSON.stringify(fraccionamiento);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.post(this.url + 'abono/lista/porverificar/' + fraccionamiento, params, {headers});
  }

  getRecordByOwner(fraccionamiento_id: any, estado: any,fechaInicial: any, fechaFinal : any, propietario : any = 0): Observable<any> {
    this.commonModel.fecha_inicial = fechaInicial;
    this.commonModel.fecha_final = fechaFinal;
    this.commonModel.estado = estado;
    this.commonModel.propietario = propietario;
    const params = JSON.stringify(this.commonModel);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.post(this.url + 'abono/lista/' + fraccionamiento_id,  params, {headers});
  }

  imprimirRecibo(abono_notificacion: any): Observable<any> {
    const params = JSON.stringify(abono_notificacion);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.post(this.url + 'avisos/recibos/buscar/' + abono_notificacion, params, {headers});
  }

  postRecord(model: any): Observable<any> {
    const params = JSON.stringify(model);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.post(this.url + 'abono/notificacion/registrar', params, {headers});
  }

  postRecordCheckReject(model: any): Observable<any> {
    const params = JSON.stringify(model);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.post(this.url + 'abono/notificacion/verificarrechazar', params, {headers});
  }

  postRecordReject(model: any): Observable<any> {
    const params = JSON.stringify(model);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.post(this.url + 'abono/notificacion/rechazar', params, {headers});
  }

}
