import { InfoBankComponent } from './../../../../../conacoha/suburb/info-bank/info-bank.component';
import { InfoContactComponent } from './../../../../../conacoha/suburb/info-contact/info-contact.component';
import { Component, DoCheck, OnInit, Input, Output, ChangeDetectionStrategy, ApplicationRef, ChangeDetectorRef } from '@angular/core';
import {NgbDropdownConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, style, transition, trigger} from '@angular/animations';
import {GradientConfig} from '../../../../../app-config';
import { GlobalVariablesService } from './../../../../../services/global-variables.service';
import { Router } from '@angular/router';
import { PaymentNotificationI } from 'src/app/models/payment-notification';
import { ToastrService } from 'ngx-toastr';
import { PaymentNotificationService } from 'src/app/services/payment-notification.service';
import { SuburbI } from 'src/app/models/suburb';
import { SuburbService } from 'src/app/services/suburb.service';
import { DataService } from 'src/app/services/data.service';
import { delay } from 'rxjs/operators';


declare let alertify: any;

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {

  public itemLst: PaymentNotificationI[] = [];
  public totalNotifications: number;

  public suburLst: SuburbI[] = [];

  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig: any;

  public fullNameUserLogin: string;
  public photoUserLogin: string;

  // variable para manejo del intervalo para actualizar loadRecordByCheck()
  private interval: any = null;

  constructor(
    public gv: GlobalVariablesService,
    private toastr: ToastrService,
    private apiService: PaymentNotificationService,
    private suburbS: SuburbService,
    private modalService: NgbModal,
    private router: Router,
    private dataService: DataService
  ) {
    this.visibleUserList = false;
    this.chatMessage = false;
    this.gradientConfig = GradientConfig.config;

  }

  ngOnInit() {

    this.fullNameUserLogin = this.gv.fullNameUserLogin;
    this.photoUserLogin = this.gv.photoUserLogin;

    if (this.photoUserLogin === '') {
      this.photoUserLogin = 'assets/images/defaultprofile.png';
    } else {
      this.photoUserLogin = this.gv.photoUserLogin;
    }

    this.loadSuburb();

   }

  onChatToggle(friendID) {
    this.friendId = friendID;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('elite-rtl')) {
      this.gradientConfig['rtl-layout'] = true;
    } else {
      this.gradientConfig['rtl-layout'] = false;
    }
  }

  logOut() {
    localStorage.clear();
  }

  selectSuburb(fraccionamientoM) {
    localStorage.setItem('fraccionamiento_id', fraccionamientoM.id.toString());
    localStorage.setItem('fraccionamiento', fraccionamientoM.nombre);
    this.gv.fraccionamientoId = localStorage.getItem('fraccionamiento_id');
    this.gv.fraccionamiento = localStorage.getItem('fraccionamiento');
    this.dataService.fraccionamientoIdChanged.emit(+localStorage.getItem('fraccionamiento_id'));
  }

  loadSuburb() {
    
    this.suburbS.usuarioFraccionamiento(this.gv.idUserLogin).subscribe(
      response => {
        this.suburLst = response;
        localStorage.setItem('fraccionamiento_id', this.suburLst[0].id.toString());
        localStorage.setItem('fraccionamiento', this.suburLst[0].nombre);
        this.gv.fraccionamientoId = localStorage.getItem('fraccionamiento_id');
        this.gv.fraccionamiento = localStorage.getItem('fraccionamiento');
        
        this.loadRecordByCheck();
  
        // asigna el intervalo a la variable interval
        this.interval = setInterval(() => {
           this.loadRecordByCheck();
          
          }, 10000);

      },
      err => {
        if (err.status === 401) {
          this.toastr.error('Sesión de usuario caducada', 'ERROR');
          // this.router.navigate(['/auth/signin']);
        }

        if (err.status === 0) {
          // this.router.navigate(['/auth/signin']);
        }
        console.error(err);

        if (err.status === 500) {
          this.toastr.error('No se puede comunicar con el servidor', 'ERROR');
          // this.router.navigate(['/auth/signin']);
        }
        console.error(err);
      }
    );
  }

  loadRecordByCheck() {

    // si el token ya no es valido, limpia el intervalo this.interval
    if (localStorage.getItem('token_access') === null) {
      clearInterval(this.interval);
      return;
    }

    // Verifica que tenga un fraccionamiento_id Valido
    if (localStorage.getItem('fraccionamiento_id') === null) {
      console.log('fraccionamiento_id no valido');
      return;
    }

    this.itemLst = [];
    this.apiService.getRecordByCheck(localStorage.getItem('fraccionamiento_id')).subscribe(
      response => {
        this.itemLst = response;
        this.totalNotifications = this.itemLst.length;
        console.log('Notificaciones');
      },
      err => {
        if (err.status === 401) {
          this.toastr.error('Sesión de usuario caducada', 'ERROR');
          this.router.navigate(['/auth/signin']);
        }

        if (err.status === 0) {
          // this.router.navigate(['/auth/signin']);
        }
        console.error(err);

        if (err.status === 500) {
          this.toastr.error('No se puede comunicar con el servidor', 'ERROR');
          // this.router.navigate(['/auth/signin']);
        }
        console.error(err);
      }
    );
  }

  contacto(){
    const modal = this.modalService.open(InfoContactComponent, { size: 'lg' });
    modal.componentInstance.dataInterfaz = this.suburLst.filter(x => x.id.toString() === localStorage.getItem('fraccionamiento_id'));
    modal.result.then((result) => {
      if (result === true) {
      }
     }).catch((result) => {});
  }

  datosBancarios(){
    const modal = this.modalService.open(InfoBankComponent, { size: 'lg' });
    modal.componentInstance.dataInterfaz = this.suburLst.filter(x => x.id.toString() === localStorage.getItem('fraccionamiento_id'));
    modal.result.then((result) => {
      if (result === true) {
      }
     }).catch((result) => {});
  }

}
