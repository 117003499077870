<div class="modal-header">
  <h4 class="modal-title">Datos de Contacto</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <h5><i class="fas fa-university"></i> Banco: {{dataInterfaz[0].banco}}</h5>
      </div>
      <div class="form-group">
        <h5><i class="fas fa-file-invoice-dollar"></i> Cuenta: {{dataInterfaz[0].cuenta}}</h5>
      </div>
      <div class="form-group">
        <h5><i class="fas fa-map-marker"></i> Clabe: {{dataInterfaz[0].clabe}}</h5>
      </div>
      <div class="form-group">
        <h5><i class="fas fa-user"></i> Beneficiario: {{dataInterfaz[0].beneficiario}}</h5>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">

  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')"><i
      class="fas fa-times"></i> Cerrar</button>
</div>
