import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalUrl } from './webapiurl';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuburbService {
  public url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = GlobalUrl.url_webapi;
  }

  getRecord(): Observable<any> {
    const headers = new HttpHeaders()
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.get(this.url + 'fraccionamiento/lista', {headers});
  }

  postRecord(model: any): Observable<any> {
    const params = JSON.stringify(model);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.post(this.url + 'fraccionamiento/registrar/', params, {headers});
  }

  usuarioFraccionamiento(usuario: any): Observable<any> {
    const params = JSON.stringify(usuario);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.post(this.url + 'fraccionamiento/buscar/' + usuario, params, {headers});
  }

  putRecord(model: { id: string; }): Observable<any> {
    const params = JSON.stringify(model);
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.put(this.url + 'fraccionamiento/editar/' + model.id, params, {headers});
  }

  delRecord(id: string): Observable<any> {
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.delete(this.url + 'fraccionamiento/eliminar/' + id, {headers});
  }
}
