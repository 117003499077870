import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuburbI } from 'src/app/models/suburb';

@Component({
  selector: 'app-info-bank',
  templateUrl: './info-bank.component.html',
  styleUrls: ['./info-bank.component.css']
})
export class InfoBankComponent implements OnInit {

  public dataInterfaz: SuburbI;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {

  }

}
