import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

// let NavigationItems = [];

// if (localStorage.getItem('perfil') === 'PROPIETARIO') {
//   NavigationItems = [
//     {
//       id: 'navigation',
//       title: 'Navegación',
//       type: 'group',
//       icon: 'feather icon-monitor',
//       children: [
//         {
//           id: 'tablero',
//           title: 'Administración',
//           type: 'collapse',
//           icon: 'fas fa-th',
//           children: [
//             {
//               id: 'estadisticas',
//               title: 'Estadística',
//               type: 'item',
//               url: '/tablero/estadistica',
//               breadcrumbs: false
//             },
//             {
//               id: 'avisos',
//               title: 'Avisos',
//               type: 'item',
//               url: '/tablero/aviso'
//             },
//             {
//               id: 'reuniones',
//               title: 'Reuniones',
//               type: 'item',
//               url: '/tablero/reunion/lista',
//             },
//             {
//               id: 'documentos',
//               title: 'Documentos',
//               type: 'item',
//               url: '/tablero/documento/lista',
//             },
//             {
//               id: 'registro-pagos',
//               title: 'Registro de Pago',
//               type: 'item',
//               url: '/tablero/abono/notificacion/lista',
//             },
//             {
//               id: 'estado-cuentas',
//               title: 'Estado de Cuenta',
//               type: 'item',
//               url: '/tablero/estado-cuenta',
//             },
//             {
//               id: 'reservaciones',
//               title: 'Reservaciones',
//               type: 'item',
//               url: '/tablero/reservacion/lista',
//             },
//             {
//               id: 'report',
//               title: 'Reportes',
//               type: 'collapse',
//               children: [
//                 {
//                   id: 'estado-cuentas-banco',
//                   title: 'Reporte Estado Cuenta',
//                   type: 'item',
//                   url: '/reporte/estado-cuenta/banco',
//                 },
//                 {
//                   id: 'gastos',
//                   title: 'Reporte Gastos',
//                   type: 'item',
//                   url: '/reporte/gastos',
//                 },
//                 {
//                   id: 'cobranzas',
//                   title: 'Reporte Cobranza',
//                   type: 'item',
//                   url: '/reporte/cobranza',
//                 },
//                 {
//                   id: 'carteras-vencidas',
//                   title: 'Reporte Cartera Vencida',
//                   type: 'item',
//                   url: '/reporte/cartera-vencida',
//                 }
//               ]
//             }
//           ]
//         }
//       ]
//     },
//     {
//       id: 'catalogs',
//       title: 'Catálogos',
//       type: 'collapse',
//       icon: 'fas fa-list',
//       children: [
//         {
//           id: 'equipo-trabajo',
//           title: 'Lista Staff',
//           type: 'item',
//           url: '/equipo/lista'
//         }
//       ]
//     }
//   ];
// }

// if (localStorage.getItem('perfil') !== 'PROPIETARIO') {
//   console.log('No es propietario');
//   NavigationItems = [
//     {
//       id: 'navigation',
//       title: 'Navegación',
//       type: 'group',
//       icon: 'fa icon-layers',
//       children: [
//         {
//           id: 'tablero',
//           title: 'Administración',
//           type: 'collapse',
//           icon: 'fas fa-th',
//           children: [
//             {
//               id: 'estadisticas',
//               title: 'Estadística',
//               type: 'item',
//               url: '/tablero/estadistica',
//               breadcrumbs: false
//             },
//             {
//               id: 'avisos',
//               title: 'Avisos',
//               type: 'item',
//               url: '/tablero/aviso',
//             },
//             {
//               id: 'reuniones',
//               title: 'Reuniones',
//               type: 'item',
//               url: '/tablero/reunion/lista',
//             },
//             {
//               id: 'documentos',
//               title: 'Documentos',
//               type: 'item',
//               url: '/tablero/documento/lista',
//             },
//             {
//               id: 'notificaciones-abono',
//               title: 'Notificación de Abono',
//               type: 'item',
//               url: '/tablero/abono/notificacion/lista',
//             },
//             {
//               id: 'estado-cuentas',
//               title: 'Estado de Cuenta',
//               type: 'item',
//               url: '/tablero/estado-cuenta',
//             },
//             {
//               id: 'reservaciones',
//               title: 'Reservaciones',
//               type: 'item',
//               url: '/tablero/reservacion/lista',
//             },
//             {
//               id: 'ingresos',
//               title: 'Ingresos',
//               type: 'item',
//               url: '/tablero/finanza/ingreso/lista',
//             },
//             {
//               id: 'gastos',
//               title: 'Gastos',
//               type: 'item',
//               url: '/tablero/finanza/gasto/lista',
//             },
//             {
//               id: 'gasto-mayor',
//               title: 'Gasto Mayor',
//               type: 'item',
//               url: '/tablero/finanza/gasto/mayor/lista',
//             },
//             {
//               id: 'gastos-caja-chica',
//               title: 'Caja Chica',
//               type: 'item',
//               url: '/tablero/finanza/gasto/caja-chica/lista',
//             },
//             {
//               id: 'reportes',
//               title: 'Reportes',
//               type: 'collapse',
//               children: [
//                 {
//                   id: 'estado-cuentas-banco',
//                   title: 'Reporte Estado Cuenta',
//                   type: 'item',
//                   url: '/reporte/estado-cuenta/banco',
//                 },
//                 {
//                   id: 'gastos',
//                   title: 'Reporte Gastos',
//                   type: 'item',
//                   url: '/reporte/gasto',
//                 },
//                 {
//                   id: 'cobranzas',
//                   title: 'Reporte Cobranza',
//                   type: 'item',
//                   url: '/reporte/cobranza',
//                 },
//                 {
//                   id: 'carteras-vencidas',
//                   title: 'Reporte Cartera Vencida',
//                   type: 'item',
//                   url: '/reporte/cartera-vencida',
//                 },
//                 {
//                   id: 'caja-chica',
//                   title: 'Reporte Caja Chica',
//                   type: 'item',
//                   url: '/reporte/caja-chica',
//                 }
//               ]
//             }
//           ]
//         },
//         {
//           id: 'catalogs',
//           title: 'Catálogos',
//           type: 'collapse',
//           icon: 'fas fa-list',
//           children: [
//             {
//               id: 'usuarios',
//               title: 'Lista Usuario',
//               type: 'item',
//               url: '/usuario/lista'
//             },
//             {
//               id: 'areas-comunes',
//               title: 'Lista Área Común',
//               type: 'item',
//               url: '/area-comun/lista'
//             },
//             {
//               id: 'perfiles',
//               title: 'Lista Perfil',
//               type: 'item',
//               url: '/perfil/lista'
//             },
//             {
//               id: 'equipo-trabajo',
//               title: 'Lista Staff',
//               type: 'item',
//               url: '/equipo/lista'
//             },
//             {
//               id: 'fraccionamientos',
//               title: 'Lista Fraccionamiento',
//               type: 'item',
//               url: '/fraccionamiento/lista'
//             },
//             {
//               id: 'viviendas',
//               title: 'Lista Vivienda',
//               type: 'item',
//               url: '/vivienda/lista'
//             },
//              {
//               id: 'propietarios',
//               title: 'Lista Propietario',
//               type: 'item',
//               url: '/propietario/lista'
//             },
//             {
//              id: 'tipo-gastos',
//              title: 'Lista Tipo Gasto',
//              type: 'item',
//              url: '/gasto-tipo/lista'
//            }
//           ]
//         }
//       ]
//     }
//   ];
// }

@Injectable()
export class NavigationItem {

  navPropietario = [
    {
      id: 'navigation',
      title: 'Navegación',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [
        {
          id: 'tablero',
          title: 'Administración',
          type: 'collapse',
          icon: 'fas fa-th',
          children: [
            {
              id: 'estadisticas',
              title: 'Estadística',
              type: 'item',
              url: '/tablero/estadistica',
              breadcrumbs: false
            },
            {
              id: 'avisos',
              title: 'Avisos',
              type: 'item',
              url: '/tablero/aviso'
            },
            {
              id: 'reuniones',
              title: 'Reuniones',
              type: 'item',
              url: '/tablero/reunion/lista',
            },
            {
              id: 'documentos',
              title: 'Documentos',
              type: 'item',
              url: '/tablero/documento/lista',
            },
            {
              id: 'registro-pagos',
              title: 'Registro de Pago',
              type: 'item',
              url: '/tablero/abono/notificacion/lista',
            },
            {
              id: 'estado-cuentas',
              title: 'Estado de Cuenta',
              type: 'item',
              url: '/tablero/estado-cuenta',
            },
            {
              id: 'reservaciones',
              title: 'Reservaciones',
              type: 'item',
              url: '/tablero/reservacion/lista',
            },
            {
              id: 'reservaciones-lista',
              title: 'Abono Reservación',
              type: 'item',
              url: '/tablero/reservacion/booking-owner-list',
            }

          ]
        },

        {
          id: 'catalogs',
          title: 'Catálogos',
          type: 'collapse',
          icon: 'fas fa-list',
          children: [
            {
              id: 'equipo-trabajo',
              title: 'Lista Staff',
              type: 'item',
              url: '/equipo/lista'
            }
          ]
        },
        {
          id: 'report',
          title: 'Reportes',
          type: 'collapse',
          icon: 'fas fa-list',
          children: [
            {
              id: 'estado-cuentas-banco',
              title: 'Reporte Estado Cuenta',
              type: 'item',
              url: '/reporte/estado-cuenta/banco',
            },
            {
              id: 'gastos',
              title: 'Reporte Gastos',
              type: 'item',
              url: '/reporte/gasto',
            },
            {
              id: 'cobranzas',
              title: 'Reporte Cobranza',
              type: 'item',
              url: '/reporte/cobranza',
            },
            {
              id: 'carteras-vencidas',
              title: 'Reporte Cartera Vencida',
              type: 'item',
              url: '/reporte/cartera-vencida',
            }
          ]
        }
      ]
    }
  ];

  navNoPropietario = [
    {
      id: 'navigation',
      title: 'Navegación',
      type: 'group',
      icon: 'fa icon-layers',
      children: [
        {
          id: 'tablero',
          title: 'Administración',
          type: 'collapse',
          icon: 'fas fa-th',
          children: [
            {
              id: 'estadisticas',
              title: 'Estadística',
              type: 'item',
              url: '/tablero/estadistica',
              breadcrumbs: false
            },
            {
              id: 'avisos',
              title: 'Avisos',
              type: 'item',
              url: '/tablero/aviso',
            },
            {
              id: 'reuniones',
              title: 'Reuniones',
              type: 'item',
              url: '/tablero/reunion/lista',
            },
            {
              id: 'documentos',
              title: 'Documentos',
              type: 'item',
              url: '/tablero/documento/lista',
            },
            {
              id: 'notificaciones-abono',
              title: 'Notificación de Abono',
              type: 'item',
              url: '/tablero/abono/notificacion/lista',
            },
            {
              id: 'estado-cuentas',
              title: 'Estado de Cuenta',
              type: 'item',
              url: '/tablero/estado-cuenta',
            },
            {
              id: 'reservaciones',
              title: 'Reservaciones',
              type: 'item',
              url: '/tablero/reservacion/lista',
            },
            {
              id: 'ingresos',
              title: 'Ingresos',
              type: 'item',
              url: '/tablero/finanza/ingreso/lista',
            },
            {
              id: 'gastos',
              title: 'Gastos',
              type: 'item',
              url: '/tablero/finanza/gasto/lista',
            }
            // ,
            // {
            //   id: 'gasto-mayor',
            //   title: 'Gasto Mayor',
            //   type: 'item',
            //   url: '/tablero/finanza/gasto/mayor/lista',
            // },
            // {
            //   id: 'gastos-caja-chica',
            //   title: 'Caja Chica',
            //   type: 'item',
            //   url: '/tablero/finanza/gasto/caja-chica/lista',
            // },
            // {
            //   id: 'pago-infonavit',
            //   title: 'Abono de Infonavit',
            //   type: 'item',
            //   url: '/administration/payment-infonavit',
            // } // reportes,

          ]
        },
        {
          id: 'catalogs',
          title: 'Catálogos',
          type: 'collapse',
          icon: 'fas fa-list',
          children: [
            {
              id: 'usuarios',
              title: 'Lista Usuario',
              type: 'item',
              url: '/usuario/lista'
            },
            {
              id: 'areas-comunes',
              title: 'Lista Área Común',
              type: 'item',
              url: '/area-comun/lista'
            },
            {
              id: 'perfiles',
              title: 'Lista Perfil',
              type: 'item',
              url: '/perfil/lista'
            },
            {
              id: 'equipo-trabajo',
              title: 'Lista Staff',
              type: 'item',
              url: '/equipo/lista'
            },
            {
              id: 'fraccionamientos',
              title: 'Lista Fraccionamiento',
              type: 'item',
              url: '/fraccionamiento/lista'
            },
            {
              id: 'viviendas',
              title: 'Lista Vivienda',
              type: 'item',
              url: '/vivienda/lista'
            },
             {
              id: 'propietarios',
              title: 'Lista Propietario',
              type: 'item',
              url: '/propietario/lista'
            },
            {
             id: 'tipo-gastos',
             title: 'Lista Tipo Gasto',
             type: 'item',
             url: '/gasto-tipo/lista'
           },
           {
            id: 'prototipo',
            title: 'Lista Prototipo',
            type: 'item',
            url: '/prototipo'
          },
          {
            id: 'tipo-credito',
            title: 'Lista Tipo Crédito',
            type: 'item',
            url: '/tipo-credito'
          }
          ]
        },
        {
          id: 'reportes',
          title: 'Reportes',
          type: 'collapse',
          icon: 'fas fa-list',
          children: [
            {
              id: 'estado-cuentas-banco',
              title: 'Reporte Estado Cuenta',
              type: 'item',
              url: '/reporte/estado-cuenta/banco',
            },
            {
              id: 'gastos',
              title: 'Reporte Gastos',
              type: 'item',
              url: '/reporte/gasto',
            },
            {
              id: 'cobranzas',
              title: 'Reporte Cobranza',
              type: 'item',
              url: '/reporte/cobranza',
            },
            {
              id: 'carteras-vencidas',
              title: 'Reporte Cartera Vencida',
              type: 'item',
              url: '/reporte/cartera-vencida',
            }
            //,
            // {
            //   id: 'caja-chica',
            //   title: 'Reporte Caja Chica',
            //   type: 'item',
            //   url: '/reporte/caja-chica',
            // }
          ]
        }
      ]
    }
  ];


  navContabilidad = [
    {
      id: 'navigation',
      title: 'Navegación',
      type: 'group',
      icon: 'fa icon-layers',
      children: [
        {
          id: 'tablero',
          title: 'Administración',
          type: 'collapse',
          icon: 'fas fa-th',
          children: [
            {
              id: 'estadisticas',
              title: 'Estadística',
              type: 'item',
              url: '/tablero/estadistica',
              breadcrumbs: false
            },
            {
              id: 'avisos',
              title: 'Avisos',
              type: 'item',
              url: '/tablero/aviso',
            },
            {
              id: 'reuniones',
              title: 'Reuniones',
              type: 'item',
              url: '/tablero/reunion/lista',
            },
            {
              id: 'documentos',
              title: 'Documentos',
              type: 'item',
              url: '/tablero/documento/lista',
            },
            {
              id: 'notificaciones-abono',
              title: 'Notificación de Abono',
              type: 'item',
              url: '/tablero/abono/notificacion/lista',
            },
            {
              id: 'estado-cuentas',
              title: 'Estado de Cuenta',
              type: 'item',
              url: '/tablero/estado-cuenta',
            },
            {
              id: 'reservaciones',
              title: 'Reservaciones',
              type: 'item',
              url: '/tablero/reservacion/lista',
            },
            {
              id: 'ingresos',
              title: 'Ingresos',
              type: 'item',
              url: '/tablero/finanza/ingreso/lista',
            },
            {
              id: 'gastos',
              title: 'Gastos',
              type: 'item',
              url: '/tablero/finanza/gasto/lista',
            },
            {
              id: 'gasto-mayor',
              title: 'Gasto Mayor',
              type: 'item',
              url: '/tablero/finanza/gasto/mayor/lista',
            },
            {
              id: 'gastos-caja-chica',
              title: 'Caja Chica',
              type: 'item',
              url: '/tablero/finanza/gasto/caja-chica/lista',
            } // reportes,

          ]
        },
        {
          id: 'catalogs',
          title: 'Catálogos',
          type: 'collapse',
          icon: 'fas fa-list',
          children: [
            {
              id: 'usuarios',
              title: 'Lista Usuario',
              type: 'item',
              url: '/usuario/lista'
            },
            {
              id: 'areas-comunes',
              title: 'Lista Área Común',
              type: 'item',
              url: '/area-comun/lista'
            },
            {
              id: 'perfiles',
              title: 'Lista Perfil',
              type: 'item',
              url: '/perfil/lista'
            },
            {
              id: 'equipo-trabajo',
              title: 'Lista Staff',
              type: 'item',
              url: '/equipo/lista'
            },
            {
              id: 'fraccionamientos',
              title: 'Lista Fraccionamiento',
              type: 'item',
              url: '/fraccionamiento/lista'
            },
            {
              id: 'viviendas',
              title: 'Lista Vivienda',
              type: 'item',
              url: '/vivienda/lista'
            },
             {
              id: 'propietarios',
              title: 'Lista Propietario',
              type: 'item',
              url: '/propietario/lista'
            },
            {
             id: 'tipo-gastos',
             title: 'Lista Tipo Gasto',
             type: 'item',
             url: '/gasto-tipo/lista'
           }
          ]
        },
        {
          id: 'reportes',
          title: 'Reportes',
          type: 'collapse',
          icon: 'fas fa-list',
          children: [
            {
              id: 'estado-cuentas-banco',
              title: 'Reporte Estado Cuenta',
              type: 'item',
              url: '/reporte/estado-cuenta/banco',
            },
            {
              id: 'gastos',
              title: 'Reporte Gastos',
              type: 'item',
              url: '/reporte/gasto',
            },
            {
              id: 'cobranzas',
              title: 'Reporte Cobranza',
              type: 'item',
              url: '/reporte/cobranza',
            },
            {
              id: 'carteras-vencidas',
              title: 'Reporte Cartera Vencida',
              type: 'item',
              url: '/reporte/cartera-vencida',
            }
            // ,
            // {
            //   id: 'caja-chica',
            //   title: 'Reporte Caja Chica',
            //   type: 'item',
            //   url: '/reporte/caja-chica',
            // }
          ]
        }
      ]
    }
  ];


  public get() {
    // return NavigationItems;

    // regresa el menu de acuerdo al perfil
    if (localStorage.getItem('perfil') !== null) {
      if (localStorage.getItem('perfil') !== 'PROPIETARIO') {
        return this.navNoPropietario
      }
      else {
        return this.navPropietario;
      }
    }
    else {
      return [];
    }
  }
}
