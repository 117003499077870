import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalUrl } from './webapiurl';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationSystemService {

  public url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = GlobalUrl.url_webapi;
  }

  getStateSystem(): Observable<any> {
    const headers = new HttpHeaders()
    .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.get(this.url + 'configuracion/estadoOperativo', {headers});
  }

}
