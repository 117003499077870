import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class DataService {

    loginChanged = new EventEmitter<boolean>();
    fraccionamientoIdChanged = new EventEmitter<number>();
    
}