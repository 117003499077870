<div class="modal-header">
  <h4 class="modal-title">Datos de Contacto</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <h5><i class="fas fa-phone"></i> Teléfono Oficina: {{dataInterfaz[0].telefono_oficina}}</h5>
      </div>
      <div class="form-group">
        <h5><i class="fas fa-phone"></i> Celular Oficina: {{dataInterfaz[0].celular_oficina}}</h5>
      </div>
      <div class="form-group">
        <h5><i class="fas fa-phone"></i> Teléfono Vigilancia: {{dataInterfaz[0].telefono_vigilancia}}</h5>
      </div>
      <div class="form-group">
        <h5><i class="fas fa-at"></i> Correo Fraccionamiento: {{dataInterfaz[0].correo_fraccionamiento}}</h5>
      </div>
      <div class="form-group">
        <h5><i class="fas fa-clock"></i> Horario de Oficina {{dataInterfaz[0].horario_oficina}}</h5>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">

  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')"><i
      class="fas fa-times"></i> Cerrar</button>
</div>
