import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import * as jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {

  public profileUserLogin: string;

  constructor(
  ) {
  }

  canActivate() {
    if (localStorage.getItem('profile') !== 'PROPIETARIO') {
      return true;
    } else {
      return false;
    }
  }

}
