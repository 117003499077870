import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { ConfigurationSystemService } from 'src/app/services/configuration-system.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

  public idUserLogin: string;
  public propietario: string;
  public fullNameUserLogin: string;
  public emailUserLogin: string;
  public phoneContactUserLogin: string;
  public profileUserLogin: string;
  public photoUserLogin: string;
  public estadoOperativo: boolean;
  public fraccionamientoId: string;
  public fraccionamiento: string;

  constructor(
    private cs: ConfigurationSystemService
  ) {
    // llama método para decodificar los datos del token
    this.DecodeToken();

  }

  public DecodeToken() {

    if (localStorage.getItem('token_access') !== null){
      const decodeJWT = jwt_decode(localStorage.getItem('token_access'));

      this.idUserLogin = decodeJWT['id'];
      this.propietario = decodeJWT['propietario'];
      this.fullNameUserLogin = decodeJWT['nombre_completo'];
      this.emailUserLogin = decodeJWT['correo'];
      this.phoneContactUserLogin = decodeJWT['telefono'];
      this.profileUserLogin = decodeJWT['perfil'];
      this.photoUserLogin = decodeJWT['foto'];
      this.loadConfigurationSystem();

      localStorage.setItem('perfil', decodeJWT['perfil'])
    }
  }

  loadConfigurationSystem() {
    this.cs.getStateSystem().subscribe(
      response => {
        this.estadoOperativo = response;
      },
      err => {
        console.error(err);
      }
    );
  }
}
