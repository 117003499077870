export interface CommonI {
  fecha_inicial: string;
  fecha_final: string;
  usuario_registra: number;
  aprobar_rechazar: number;
  estado: number;
  tipo_gasto: number;
  motivo_cancelacion: string;
  comentario_texto: string;
  comentario_monto: number;
  area_comun_id: string;
  seccion_id: number
  vivienda_id: number;
  cuantas_cuotas_inicial: number;
  cuantas_cuotas_final: number;
  fraccionamiento_id: number;
  propietario: number;
  reservacion_fecha: Date;
}


export class CommonC implements CommonI {
  fecha_inicial: string = '';
  fecha_final: string = '';
  usuario_registra: number = null;
  aprobar_rechazar: number = null;
  estado: number = null;
  tipo_gasto: number = null;
  motivo_cancelacion: string = '';
  comentario_texto: string = '';
  comentario_monto: number = null;
  area_comun_id: string = '';
  seccion_id: number = null;
  vivienda_id: number = null;
  cuantas_cuotas_inicial: number = null;
  cuantas_cuotas_final: number = null;
  fraccionamiento_id: number = null;
  propietario: number = 0;
  reservacion_fecha: Date;

}
