import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) {

  }

  canActivate() {
    if (localStorage.getItem('token_access') != null) {
      return true;
    } else {
      this.router.navigate(['/auth/signin']);
      return false;
    }
  }

}
