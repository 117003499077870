<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="fas fa-city"></i> {{gv.fraccionamiento}}
      </a>
      <a href="javascript:" (click)="datosBancarios()" class="ml-2">
        <i class="fas fa-university"></i> Datos Bancarios
      </a>
      <a href="javascript:" (click)="contacto()" class="ml-2">
        <i class="fas fa-phone"></i> Datos Contacto
      </a>
      <div class="dropdown-menu dropdown-menu-button profile-notification" ngbDropdownMenu>
        <ul class="pro-body">
          <li *ngFor="let item of suburLst"><a href="javascript:" (click)="selectSuburb(item)" class="dropdown-item"><i class="feather icon-home"></i> {{item.nombre}}</a></li>
        </ul>
      </div>
    </div>
  </li>

  <li>
    <div class="dropdown" ngbDropdown placement="auto" *ngIf="gv.profileUserLogin !== 'PROPIETARIO'">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"><i class="icon feather icon-bell"></i><span class="badge bg-danger">{{totalNotifications}}</span></a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notificaciones</h6>
          <div class="float-right">
          </div>
        </div>
        <ul class="noti-body">
          <li class="notification" *ngFor="let item of itemLst">
            <div class="media">
              <div class="media-body">
                <p><strong>{{item.fraccionamiento}}</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>{{item.fecha_registra | amLocale: 'es' | amTimeAgo}}</span></p>
                <p>{{item.referencia + ' - ' + item.calle + ' - ' + item.numero}}</p>
                <p>{{item.monto | currency}}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <img src={{photoUserLogin}} class="img-radius wid-40" alt="User-Profile-Image">
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head text-center">
          <span>{{fullNameUserLogin}}</span>
        </div>
        <ul class="pro-body">
          <!-- <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Perfil</a></li> -->
          <li><a class="dropdown-item" href="#" (click)="logOut()"><i class="feather icon-log-out"></i> Salir</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>
