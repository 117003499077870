import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuard } from './guards/auth.guard';
import { ProfileGuard } from './guards/profile.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: '/auth/signin',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: () => import('./conacoha/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'tablero',
        loadChildren: () => import('./conacoha/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'usuario',
        loadChildren: () => import('./conacoha/user/user.module').then(module => module.UserModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'area-comun',
        loadChildren: () => import('./conacoha/common-area/common-area.module').then(module => module.CommonAreaModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'perfil',
        loadChildren: () => import('./conacoha/profile/profile.module').then(module => module.ProfileModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'equipo',
        loadChildren: () => import('./conacoha/staff/staff.module').then(module => module.StaffModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'fraccionamiento',
        loadChildren: () => import('./conacoha/suburb/suburb.module').then(module => module.SuburbModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'vivienda',
        loadChildren: () => import('./conacoha/dwelling/dwelling.module').then(module => module.DwellingModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'propietario',
        loadChildren: () => import('./conacoha/owner/owner.module').then(module => module.OwnerModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'gasto-tipo',
        loadChildren: () => import('./conacoha/finance/expenses-catalog/expenses-catalog.module').then(module => module.ExpensesCatalogModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'prototipo',
        loadChildren: () => import('./conacoha/prototype/prototype.module').then(module => module.PrototypeModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'tipo-credito',
        loadChildren: () => import('./conacoha/credit-type/credit-type.module').then(module => module.CreditTypeModule),
        canActivate: [ProfileGuard]
      },
      {
        path: 'reporte',
        loadChildren: () => import('./conacoha/reports/reports.module').then(module => module.ReportsModule)
      },
      {
        path: 'administration',
        loadChildren: () => import('./conacoha/administration/administration.module').then(module => module.AdministrationModule)
      }
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],

exports: [RouterModule]
})
export class AppRoutingModule { }
