// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebaseConfig: {
  //   apiKey: 'AIzaSyDTuOYAmzuYuNhIb-ImhELJBfwjppwmHbA',
  //   authDomain: 'conacoha-9a922.firebaseapp.com',
  //   databaseURL: 'https://conacoha-9a922.firebaseio.com',
  //   projectId: 'conacoha-9a922',
  //   storageBucket: 'conacoha-9a922.appspot.com',
  //   messagingSenderId: '174073330870',
  //   appId: '1:174073330870:web:9a7709f912e96d98c87a17',
  //   measurementId: 'G-T2X3CEWQ4T'
  // }
  firebaseConfig: {
    apiKey: "AIzaSyC9OXPh5Cm08YeQdtdeDU9PnNugiTXAac4",
    authDomain: "convives-f7ac8.firebaseapp.com",
    databaseURL: 'https://conacoha-9a922.firebaseio.com',
    projectId: "convives-f7ac8",
    storageBucket: "convives-f7ac8.appspot.com",
    messagingSenderId: "286894457290",
    appId: "1:286894457290:web:919d7a7d752fa9f2f04f33",
    measurementId: "G-E27SQHTPP1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
